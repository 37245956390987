import axios from 'axios';

var token = null;

if (process.browser) {
  token = localStorage.getItem('token');
}
const http = axios.create({
  baseURL: process.env.BASE_URL,
  // headers: {
  //   Authorization: token,
  //   'Content-type': 'application/json',
  // },
});

class ProjectsService {
  getAllProjects() {
    return http.get(`/api/v1/projects`);
  }
  addProject(project){
    return http.post(`/api/v1/projects`, project)
  }

  deleteProject(project){
    return http.delete(`/api/v1/projects/${project}`)
  }
  getProject(project){
    return http.get(`/api/v1/projects/${project}`)
  }
  updateProject(id, project){
    return http.put(`/api/v1/projects/${id}`, project)
  }
}

export default new ProjectsService();
