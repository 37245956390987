import React from 'react';
import Dashboard from '../Dashboard';
const Home = () => {
  return (
    <Dashboard>
      <div className="mt-20">Home</div>
    </Dashboard>
  );
};

export default Home;
