import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Components/layout/Layout';
import Paypal from '../Assets/paypal.png';
import Visa from '../Assets/visa.png';
import Mastercard from '../Assets/mastercard.png';

const Donate1 = () => {
  const [open, setOpen] = useState(false);
  const hundleinput = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <Layout>
      <div classNameName="bg-gray-200 flex h-screen justify-center items-center">
        <form className="w-full max-w-lg  mx-auto">
          <div className="mb-1 text-footer text-center font-medium mt-8 font-sans">Step 1 of 5</div>
          <div className="w-4/5 mx-auto bg-gray-200 rounded-full h-1.5 mb-12">
            <div className="bg-primary h-1.5 rounded-full dark:bg-blue-500 w-1/6"></div>
          </div>

          <div className="w-full px-3 mt-10 mb-6">
            <h1 className="block text-center uppercase tracking-wide text-footer font-lato text-sm font-bold mb-4">
              Donate to WaterAid
            </h1>
            <p className="text-footer font-rubik text-center text-sm">
              Your donation could help provide handwashing stations for 3 families in Mozambique.
            </p>
          </div>

          <div className="flex flex-wrap mx-3 mb-2">
            <div className="w-full md:w-1/4 px-3 mb-6">
              <button
                className="appearance-none block w-full bg-white hover:bg-footer hover:text-white text-footer text-center border border-footer rounded py-1.5 px-3 leading-tight focus:outline-none focus:bg-footer focus:text-white focus:border-footer"
                onClick={(e) => e.preventDefault()}>
                $100
              </button>
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6">
              <button
                className="appearance-none block w-full bg-white text-center hover:bg-footer hover:text-white text-gray-700 border border-footer rounded py-1.5 px-3 leading-tight focus:outline-none focus:bg-footer focus:text-white focus:border-gray-500"
                onClick={(e) => e.preventDefault()}>
                $500
              </button>
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6">
              <button
                className="appearance-none block w-full bg-white text-center text-footer border border-black hover:bg-footer hover:text-white rounded py-1.5 px-3 leading-tight focus:outline-none focus:bg-footer focus:text-white focus:border-gray-500"
                onClick={(e) => e.preventDefault()}>
                $700
              </button>
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6">
              <button
                className="appearance-none block w-full text-center bg-white hover:bg-footer hover:text-white text-gray-700 border border-footer rounded py-1.5 px-3 leading-tight focus:outline-none focus:bg-footer focus:text-white focus:border-gray-500"
                onClick={hundleinput}>
                other...
              </button>
            </div>
            {open ? (
              <input
                className="block uppercase pl-4 w-full py-2 tracking-wide leading-tight border border-footer rounded bg-white text-gray-700 text-xs my-2 mx-4 focus:outline-none focus:bg-white  focus:border-gray-500"
                id="grid-password"
                type="text"
                defaultValue='$1000'
              />
            ) : (
              ''
            )}
          </div>
          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3">
              <button
                className="block uppercase w-full py-3 tracking-wide font-sans border leading-tight border-white rounded bg-primary text-center text-white text-xs font-bold my-2"
                for="grid-password">
                <Link to="/donete-step2">Donate</Link>
              </button>
            </div>
          </div>

          <div className="flex flex-wrap mx-32 mt-4">
            <div className="w-full md:w-1/4 px-3 ">
              <img
                src={Visa}
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-city"
              />
            </div>
            <div className="w-full md:w-1/4 px-3 -mt-1">
              <img
                src={Mastercard}
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-city"
              />
            </div>
            <div className="w-full md:w-1/4 px-3 ">
              <img
                src={Paypal}
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-city"
              />
            </div>
            <div className="w-full md:w-1/4 px-3 -mt-2">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvWpySSZznnwSgRR4bSVAjAK5gfa9_rcFZwg&usqp=CAU"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-city"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Donate1;
