import {
  SERVICES_ALL_SUCCESS,
  SERVICES_ALL_LOADING,
  SERVICES_ALL_ERROR
} from '../constants/services.constants';
import Service from '../services/Services';

export const getAllServices = () => async (dispatch) => {
  dispatch({ type: SERVICES_ALL_LOADING });
  try {
    const res = await Service.getAllServices();
    dispatch({
      type: SERVICES_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: SERVICES_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const addService = (name, description) => async (dispatch) => {
  dispatch({ type: SERVICES_ALL_LOADING });
  try {
    const res = await Service.addService({
      name: name,
      description: description
    });
    dispatch({
      type: SERVICES_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: SERVICES_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const deleteService = (service_id) => async (dispatch) => {
  dispatch({ type: SERVICES_ALL_LOADING });
  try {
    const res = await Service.deleteService(service_id);
    dispatch({
      type: SERVICES_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: SERVICES_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const getService = (service_id) => async (dispatch) => {
  dispatch({ type: SERVICES_ALL_LOADING });
  try {
    const res = await Service.getService(service_id);
    dispatch({
      type: SERVICES_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: SERVICES_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const updateService = (service_id, name, description) => async (dispatch) => {
  dispatch({ type: SERVICES_ALL_LOADING });
  try {
    const res = await Service.updateService(service_id, {
      name: name,
      description: description
    });
    dispatch({
      type: SERVICES_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: SERVICES_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};
