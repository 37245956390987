import React from 'react';
import '@react-firebase/auth';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../Components/NotFound';
import { AuthProvider } from '../Context/AuthContext';
import Dashboard from '../dashboardComponents/Dashboard';
import Login from '../Components/Login';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import PostPage from '../pages/PostPage';
import Donation1 from '../pages/Donate1';
import Donation2 from '../pages/Donate2';
import Donation3 from '../pages/Donate3';
import Donation4 from '../pages/Donate4';
import Donation5 from '../pages/Donate5';
import Home from '../dashboardComponents/pages/Home';
import Service from '../pages/ServicesPage';
import Services from '../dashboardComponents/pages/Services';
import Faq from '../dashboardComponents/pages/Faq';
import Project from '../dashboardComponents/pages/Project';
import Users from '../dashboardComponents/pages/Users';
import History from '../dashboardComponents/pages/History';
import Notification from '../dashboardComponents/pages/Notification';
import Donation from '../dashboardComponents/pages/Donation';
import Search from '../dashboardComponents/pages/Search';
import Profile from '../dashboardComponents/pages/Profile';
import Payment from '../dashboardComponents/pages/Payment';
import Account from '../dashboardComponents/pages/Account';
import Testimonials from '../dashboardComponents/pages/Testimonials';
import Garrely from '../dashboardComponents/pages/Garrely';
import Support from '../dashboardComponents/pages/Support';
export default function index() {
  return (
    <AuthProvider>
      <Switch>
        
        <Route path="/login" exact component={Login} />
        <Route path="/about" exact component={AboutPage} />
        <Route path="/service" exact component={Service} />
        <Route path="/project" exact component={PostPage} />
        <Route path="/donete-step1" exact component={Donation1} />
        <Route path="/donete-step2" exact component={Donation2} />
        <Route path="/donete-step3" exact component={Donation3} />
        <Route path="/donete-step4" exact component={Donation4} />
        <Route path="/donete-step5" exact component={Donation5} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/home" exact component={Home} />
        <Route path="/services" exact component={Services} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/website/project" exact component={Project} />
        <Route path="/website/services" exact component={Services} />
        <Route path="/website/testimonials" exact component={Testimonials} />
        <Route path="/website/garrely" exact component={Garrely} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/settings/account" exact component={Account} />
        <Route path="/settings/finances/payment" exact component={Payment} />
        <Route path="/settings/finances/donation" exact component={Donation} />
        <Route path="/settings/notifications" exact component={Notification} />
        <Route path="/search" exact component={Search} />
        <Route path="/history" exact component={History} />
        <Route path="/support" exact component={Support} />
        <Route path="/users" exact component={Users} />
        <Route path="/" exact component={HomePage} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </AuthProvider>
  );
}
