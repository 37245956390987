import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Components/layout/Layout';
import LeftArrow from '../Assets/leftArrow.png';
import { Popup } from '../Components/Popup';

const Donate5 = () => {
  return (
    <Layout>
      <div classNameName="flex h-screen justify-center items-center">
        <form className="w-full max-w-lg mx-auto">
          <Link to="/donete-step4">
            <img className="h-4 w-4 -mb-6" src={LeftArrow} />
          </Link>
          <div className="mb-1 text-footer text-center font-medium mt-8 font-sans">Step 5 of 5</div>
          <div className="w-4/5 mx-auto bg-gray-200 rounded-full h-1.5 mb-6">
            <div className="bg-primary h-1.5 rounded-full dark:bg-blue-500 w-full"></div>
          </div>

          <div className="w-full px-3 mb-6">
            <p className="text-footer text-center text-sm font-rubik">
              You are making a one-off donation of $500.
            </p>
            <h1 className="block text-center uppercase tracking-wide font-lato text-footer text-sm mb-4">
              Your payment details
            </h1>
            <h5 className="block text-center uppercase tracking-wide font-rubik text-footer text-xs">
              Select payment method
            </h5>
          </div>
          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3">
            <Popup/>
              <button
                className="block uppercase w-full py-3 tracking-wide border leading-tight border-white rounded bg-primary text-center text-white text-xs font-bold font-sans mt-4 my-2"
                for="grid-password"
                onClick={(e)=>e.preventDefault()}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Donate5;
