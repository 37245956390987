import {
  SERVICES_ALL_SUCCESS,
  SERVICES_ALL_LOADING,
  SERVICES_ALL_ERROR
} from "../constants/services.constants";

const initialState = {
  error: false,
  services: []
}

export const servicesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICES_ALL_LOADING:
      return { ...state, loading: true, error: false }
    case SERVICES_ALL_ERROR:
      return { ...state, loading: false, error: payload };
    case SERVICES_ALL_SUCCESS:
      return { ...state, loading: false, error: false, services: payload };
    default:
      return state;
  }
};