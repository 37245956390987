import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import { Mixpanel } from '../mixpanel';
import Loader from './Loader';

function SignIn(props) {
  const history = useHistory();
  const userEmail = useRef('');
  const userPassword = useRef('');
  const [phoneNumber] = useState('');
  const [loginError, setLoginError] = useState('');
  const { signin, currentUser } = useAuth();
  const [error, setError] = useState({
    email: '',
    password: '',
    phoneNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [loginOption, setLoginOption] = useState('password');

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: '' });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (userEmail.current.value === '') return setError({ ...error, email: 'email  is required' });
    if (userPassword.current.value === '')
      return setError({ ...error, password: 'password  is required' });

    try {
      setLoading(!loading);
      await signin(userEmail.current.value, userPassword.current.value)
        .then((userCreds) => {
          Mixpanel.login(userCreds.user.uid);
          history.push('/creator/dashboard/events');
        })
        .catch(() => {
          setLoginError('Incorrect Username or Password');
          setLoading(false);
        });
    } catch (err) {
      setError({ email: err, password: err, phoneNumber: err });
    }
    if (currentUser) {
    }
  }

  return (
    <>
      <div>
        {loginOption === 'password' ? (
          <>
            {loginError && <p className="mb-2 text-red">{loginError}</p>}
            <div className="mb-3">
              <label className="text-darkBlack font-bold capitalize">Email</label>
              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                type="email"
                name="email"
                onChange={onChange}
                ref={userEmail}
              />
              {error && error.email && <span className="text-sm text-Red">{error.email}</span>}
            </div>
            <div className="mb-3">
              <label className="text-darkBlack font-bold capitalize">Password</label>
              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                type="password"
                name="password"
                onChange={onChange}
                ref={userPassword}
              />
              {error && error.password && (
                <span className="text-sm text-Red">{error.password}</span>
              )}
            </div>
            <button
              className="text-xl font-bold my-3 w-full mx-auto bg-black rounded text-white py-2 px-14 block outline-none"
              type="button"
              onClick={handleSubmit}
              disabled={loading}>
              {loading && <Loader />}
              Log in
            </button>
          </>
        ) : (
          loginOption === 'phone' &&
          phoneNumber && (
            <div>

              <div id="recaptcha-container"></div>
              <p
                className="text-black font-bold text-center mb-32 cursor-pointer"
                onClick={() => {
                  setLoginOption('password');
                }}>
                Log In with Email
              </p>
            </div>
          )
        )}
        <div id="recaptcha-container"></div>
      </div>
    </>
  );
}

export default SignIn;
