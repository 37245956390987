import React from 'react';
// import './Popup.css';
import { payment } from './payment';
export const Popup = ({ text, closePopup }) => {
  return (
    <div className="popup-container -z-10">
      {/* <button onClick={closePopup}>Close X</button> */}
      <div className="popup-body">
        <h1 className="text-Orange font-bold py-5 -z-10">{text}</h1>
        {payment}
      </div>
    </div>
  );
};
