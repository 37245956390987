import {
  PROJECTS_ALL_SUCCESS,
  PROJECTS_ALL_LOADING,
  PROJECTS_ALL_ERROR
} from '../constants/projects.constants';
import ProjectsService from '../services/projects.services';

export const getAllProjects = () => async (dispatch) => {
  dispatch({ type: PROJECTS_ALL_LOADING });
  try {
    const res = await ProjectsService.getAllProjects();
    dispatch({
      type: PROJECTS_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: PROJECTS_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const addProject = (title, description, image, start, end) => async (dispatch) => {
  dispatch({ type: PROJECTS_ALL_LOADING });
  try {
    const res = await ProjectsService.addProject({
      title: title,
      description: description,
      image: image,
      start: start,
      end: end
    });
    dispatch({
      type: PROJECTS_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: PROJECTS_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const deleteProject = (project_id) => async (dispatch) => {
  dispatch({ type: PROJECTS_ALL_LOADING });
  try {
    const res = await ProjectsService.deleteProject(project_id);
    dispatch({
      type: PROJECTS_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: PROJECTS_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const getProject = (project_id) => async (dispatch) => {
  dispatch({ type: PROJECTS_ALL_LOADING });
  try {
    const res = await ProjectsService.getProject(project_id);
    dispatch({
      type: PROJECTS_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: PROJECTS_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};

export const updateProject = (project_id, title, description, image, start, end) => async (dispatch) => {
  dispatch({ type: PROJECTS_ALL_LOADING });
  try {
    const res = await ProjectsService.updateProject(project_id, {
      title: title,
      description: description,
      image: image,
      start: start,
      end: end
    });
    dispatch({
      type: PROJECTS_ALL_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({ type: PROJECTS_ALL_ERROR, payload: err.response.status });
    return Promise.reject(err);
  }
};
