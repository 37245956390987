import service1 from '../Assets/image/service1.png';
import service3 from '../Assets/image/AAEAAQAAAAAAAAgYAAAAJGZiNjcwZTY1LTMwMzgtNGZkNy04NmNhLTc3ZjIxNDMyNzBkMA.jpg';
import service2 from '../Assets/image/drilling-for-wells.jpg';
import service4 from '../Assets/image/testPumping.PNG';
import service5 from '../Assets/image/PumpingInstallation.PNG';
export const services = [
  {
    name: 'Geological and Hydro geological survey',
    description:
      'Geophysical survey considers different parameters such as hydrogeological aspects, altitude, soil types, vegetation covers… for determining the types of aquifers for the purposes of ground water feasibility, the aquifer is characterized by Porosity i.e the volume of open spaces and permeability i.e interconnections of pores and water flow potential. There are various methods used for ground water study PQWT is one of the Geoelectric Instruments for Survey Profile of the underground layer based on the difference in the conductivity of rock material, soil minerals or underground liquid. Generally used as a basis for underground water drilling. Groundwater is stored in and travels through fractures, joints, and other weaknesses in the rock Drilling should be carried out with an appropriate tool - either percussion or rotary machines will be suitable, though the latter are considerably faster.  However due to stable sub ground condition air rotary drilling is the most suitable method',
    img: service1,
    url: 'https://skill-verification.vercel.app/'
  },
  {
    name: 'Drilling of water wells',
    description:
      'With over 5 years of experience in drilling, completing, and bringing water wells as a solution of\nwater shortage. As a result, we are confident we can meet your needs no matter what kind of\nwater well you are drilling.Our equipment, our process, and our schedules, they are all flexible to work with your specific application, and reliable to get the job done quickly',
    subDescription:
      'Gaia Survey Rwanda Ltd team is experienced in installation of water pipeline and its supply. We\nwill help you to carry your water from the storage to your house at any distance. We will never\nregret choosing our team.',
    subTitle: 'We support numerous well applications, including:',
    figureTitle: 'supply and pipeline construction',
    applications: {
      1: ' Municipal',
      2: ' Commercial',
      3: ' Industrial',
      4: ' Residential',
      5: ' Agricultural (irrigation)',
      6: ' Well rehab services'
    },
    img: service2,
    url: 'https://bts-corp.github.io/EthFaucet/'
  },
  {
    name: 'Design and construction of Irrigation scheme',
    description:
      ' Gaia Survey Rwanda Ltd is a division of Gaia Survey AB Sweden, Rwandan registered company which specializes in hydro- Geology Surveys and borehole drilling.',
    img: service3,
    url: 'http://ether-wallet-iota.vercel.app/'
  },

  // {
  //   name: 'Rainwater Harvesting and Treatment',
  //   description:
  //     'During the rainy season, large part of the rainfall is lost as runoff without being used by plants,\nanimals or other human activity. The runoff causes erosion of fertile soils and eventually causes\nflooding downstream. Gaia Survey Rwanda Ltd will help you with simple effective technology\n\nto increase resilience to droughts. It keeps the rainfall closer to where it falls and store it treated\nfor any use afterwards reducing runoff and risk of flooding. With this operation households,\nfarms, schools, hospitals and so on can save their bills and reducing amount that would otherwise\ncause flooding.',
  //   // "img":"https://res.cloudinary.com/chrysostomes/image/upload/v1658329783/Screenshot_26_tbfnxf.png",
  //   url: 'https://polling-system.vercel.app/'
  // },
  {
    name: 'Test Pumping',
    description:
      'A pumping test is a field experiment in which a well is pumped at a controlled rate and water-\nlevel response (drawdown) is measured in one or more surrounding observation wells and\noptionally in the pumped well (control well) itself; response data from pumping tests are used to\nestimate the hydraulic properties of aquifers, evaluate well performance and identify aquifer\nboundaries. Aquifer test and aquifer performance test are alternate designations for a pumping\ntest. In petroleum engineering, a pumping test is referred to as a drawdown test.',
    img: service4,
    url: 'https://marketplace-five-mu.vercel.app/'
  },
  {
    name: 'Pump Installations',
    description:
      'Gaia Survey Rwanda Ltd pump technicians have expertise ranging from the installation of\nsubmersible pumps, to the setup of complex constant pressure systems. We have experience in a\nwide variety of residential, and commercial pump systems, we’ll help you find the right system\nbased on your property’s needs, and install it properly.',
    subTitle:
      'We are experienced in installing. Here are the different types of pump systems you can expect us\nto install:',
    applications: {
      1: ' Submersible Pump Systems ( Electrical and Solar)',
      2: ' Hand Pumps',
      3: ' Water Tanks',
      4: ' Storage Tanks',
      5: ' Atmospheric tanks'
    },
    img: service5,
    url: 'https://bts-corp.github.io/Simple-Storage/'
  }
];
