import React from 'react';
import Dashboard from '../Dashboard';
const Profile = () => {
  return (
    <Dashboard>
      <div className="mt-20">Profile</div>
    </Dashboard>
  );
};

export default Profile;
