import React from 'react';
import Checked from '../../Assets/checked.png';

const ServicesItem = (props) => {
  return (
    <div className="col-span-1 space-y-8" >
      <div className="bg-gray-200 p-2 border-2 border-gray-200 h-auto  rounded-lg" >
        <img
          src={props.image}
          style={{ height: '15rem' }}
          className="w-full  md:h-auto mb-3 rounded-2xl object-cover h-auto w-full"
          alt={props.id}
        />
        <div>
        <h2 className="mb-1 font-lato text-footer">{props.title}</h2>
          <p className="lg:text-xs font-rubik  text-xs text-gray-700" >{props.description}</p>
        </div>
      </div>
     
     
 
    </div>
  );
};
export default ServicesItem;
