import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Components/layout/Layout';
import LeftArrow from '../Assets/leftArrow.png';

const Donate4 = () => {
  return (
    <Layout>
      <div classNameName="flex h-screen justify-center items-center">
        <form className="w-full max-w-lg mx-auto">
          <Link to="/donete-step3">
            <img className="h-4 w-4 -mb-6" src={LeftArrow} />
          </Link>
          <div className="mb-1 text-footer text-center font-medium mt-8 font-sans">Step 4 of 5</div>
          <div className="w-4/5 mx-auto bg-gray-200 rounded-full h-1.5 mb-6">
            <div className="bg-primary h-1.5 rounded-full dark:bg-blue-500 w-3/4"></div>
          </div>

          <div className="w-full px-3 mb-6">
            <p className="text-footer text-center text-sm font-rubik">
              You are making a one-off donation of $500.
            </p>
            <h1 className="block text-center uppercase font-lato tracking-wide text-footer text-sm mb-4">
              Help us to reach more people
            </h1>
          </div>

          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3">
              <p className="text-footer font-rubik text-left text-xs">Reason for donating</p>

              <div class="grid grid-cols-2 -mx-3 mb-6 justify-between">
                <div className="flex items-center pl-4">
                  <input
                    checked
                    id="bordered-radio-2"
                    type="radio"
                    value=""
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="bordered-radio-2"
                    className="py-4 ml-2 w-full text-xs font-rubik  text-gray-900">
                    Sponsored event
                  </label>
                </div>
                <div className="flex items-center pl-4">
                  <input
                    checked
                    id="bordered-radio-2"
                    type="radio"
                    value=""
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="bordered-radio-2"
                    className="py-4 ml-2 w-full text-sm  text-gray-900">
                    TV Advert
                  </label>
                </div>
                <div className="flex items-center pl-4">
                  <input
                    checked
                    id="bordered-radio-2"
                    type="radio"
                    value=""
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="bordered-radio-2"
                    className="py-4 ml-2 w-full text-xs font-rubik text-gray-900">
                    In memory
                  </label>
                </div>
                <div className="flex items-center pl-4">
                  <input
                    checked
                    id="bordered-radio-2"
                    type="radio"
                    value=""
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="bordered-radio-2"
                    className="py-4 ml-2 w-full text-xs font-rubik text-gray-900">
                    Faith Group
                  </label>
                </div>
                <div className="flex items-center pl-4">
                  <input
                    checked
                    id="bordered-radio-2"
                    type="radio"
                    value=""
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="bordered-radio-2"
                    className="py-4 ml-2 w-1/2 text-xs font-rubik text-gray-900">
                    Recommended By Friend/Family
                  </label>
                </div>
                <div className="flex items-center pl-4">
                  <input
                    checked
                    id="bordered-radio-2"
                    type="radio"
                    value=""
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    for="bordered-radio-2"
                    className="py-4 ml-2 w-full text-xs font-rubik text-gray-900">
                    Other…
                  </label>
                </div>
              </div>

              <button
                className="block uppercase w-full py-3 tracking-wide border font-sans leading-tight border-white rounded bg-primary text-center text-white text-xs font-bold mt-4 my-2"
                for="grid-password">
                <Link to="/donete-step5">Next step</Link>
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Donate4;
