import Images1 from './Assets/image/how-to-drill-your-own-water-well-fb.jpg';
import Images2 from './Assets/image/field-of-mid-growth-cotton-at-fruit-set-stage-being-furrow-irrigated-CEMA3E.jpg';
import Images3 from './Assets/image/PolyPipe-Irrigation1.jpg';
import Image_1 from './Assets/image/borehole.png';
import Image_2 from './Assets/image/drilling.png';
import Image_3 from './Assets/image/best-water-purifier-for-borewell-water.jpg';

import Image_drilling_eq_1 from './Assets/image/drilling_eq1.jpg';
import Image_drilling_eq_2 from './Assets/image/drilling_eq2.jpg';
import Image_generator_eq_1 from './Assets/image/generator_1.PNG';
import Image_generator_eq_2 from './Assets/image/generator_2.PNG';
import Image_eq_3 from './Assets/image/best-water-purifier-for-borewell-water.jpg';
export const RightSideBarData = [
  {
    id: 1,
    title: 'Preliminary Site Characterization',
    image: Images1,
    content:
      ' Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link, use a button and change it'
  },
  {
    id: 2,
    title: 'supply and pipeline construction',
    image: Images1,
    content:
      'Gaia Survey Rwanda Ltd team is experienced in installation of water pipeline and its supply. We will help you to carry your water from the storage to your house at any distance. We will never regret choosing our team'
  }
];

export const ourServices = [
  {
    id: 1,
    title: 'DRILLING OF WATER WELLS',
    description:
      'With over 5 years of experience in drilling, completing, and bringing water wells as a solution of water shortage. ',
    urls: Image_2
  },
  {
    id: 2,
    title: 'SUPPLY AND PIPELINE CONSTRUCTION',
    description:
      'Gaia Survey Rwanda Ltd team is experienced in installation of water pipeline and its supply.',
    urls: Image_3
  },
  { id: 3,
    title: 'POWER, STORAGE,PUMP AND TREATMENT',
    description:
      'Gaia Survey Rwanda Ltd technicians have expertise ranging from the installation of submersible pumps. ',
    urls: Image_1
  },
];
export const ourEquipments = [
  {
    id: 1,
    title: 'DRILLING MACHINE',
    description:
      '',
    urls: Image_drilling_eq_1,
    urls_1: Image_drilling_eq_2
  },
  {
    id: 2,
    title: 'GENERATOR',
    description:
      '',
    urls: Image_generator_eq_1,
    urls_1: Image_generator_eq_2
  },
 
 
 
];
export const Links = [
  { id: 1, name: 'Home', link: '/' },
  { id: 2, name: 'About', link: '/about' },
  // { id: 3, name: 'Services', link: '/service' },
  // { id: 3, name: 'Project', link: '/project' },
  // { id: 4, name: 'Contact Us', link: '/contact' }
];

export const expectation = [
  {
    id: 1,
    title: 'Trusted & experienced',
    content:
      'We have years of experience, and honesty is our most important quality. We’ve served hundreds of clients over the years and we’ve always maintained an exceptional reputation'
  },
  {
    id: 2,
    title: 'Quality workmanship',
    content:
      'Whether we’re working on your floor, roof, kitchen or any other part of your home, we’ll always maintain the highest standards for our services'
  },
  {
    id: 3,
    title: 'Reliable & Fully insured',
    content:
      ' When you’re planning construction or renovation, it’s important to choose a contractor who is qualified and trustworthy. You can rest easy knowing that we are fully insured and bonded.'
  },
  {
    id: 4,
    title: 'Satisfaction Guaranteed',
    content:
      'Our main goal is to keep our clients satisfied. We’ll make sure the project exceeds your expectations in every way.'
  }
];
