import React from 'react';
import Layout from '../Components/layout/Layout';
import { services } from '../utils/services.js';

import { Icon } from '@iconify/react';
import Contact from '../Components/Contact';

const AboutPage = () => {
  return (
    <Layout>
      <div className="pb-24 pt-8 bg-white md:px-32">
        <div className="bg-opacity-50 w-screen lg:w-full flex items-center justify-center">
          <p className="lg:text-3xl text-xl text-footer text-white font-bold pt-8">Our Services</p>
        </div>
        <hr className="lg:w-32 w-10 lg:my-2 h-1 border-none text-center mb-3  items-center mx-auto bg-primary" />
        <div className="mx-2 grid grid-cols-1 lg:px-40 my-8">
          <div className="lg:pr-0">
            <p className="font-bold text-gray-700">
              Gaia Survey Rwanda Ltd is a division of Gaia Survey AB Sweden, Rwandan registered
              company which specializes in hydro- Geology Surveys and borehole drilling. <br />
              We carry out all small and large commercial borehole drilling operations. We also
              construct boreholes for irrigation system, High quality engineering sampling and
              mineral exploration
            </p>
          </div>
        </div>
        {services.map((pro, index) => {
          return (
            <div className="flex items-center justify-center my-4">
              <div
                key={index}
                className="shadow-xl p-8 cursor-pointer text-footer mb-8 flex flex-col w-full lg:w-4/5 xl:mx-2"
                target="_blank"
                rel="noreferrer">
                <div className="block md:flex items-center justify-center">
                  <div className={'w-3/4'}>
                    <h1 className="mt-5 text-xl font-bold">{pro.name} </h1>
                    <p className="my-5 text-md font-lato font-bold text-gray-700">
                      {pro.description}
                    </p>
                    <p className="my-5 text-md font-lato font-bold text-gray-700">
                      {pro.subDescription}
                    </p>
                    {/*<h1 className="my-5 text-md font-lato font-bold text-primary">*/}
                    {/*  {pro.subTitle}*/}
                    {/*</h1>*/}
                  </div>
                  <div className={'flex md:w-1/6 w-1/2 justify-end'}>
                    <img className={'rounded w-32 h-32 object-cover'} src={pro.img} alt={'image'} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Contact />
    </Layout>
  );
};

export default AboutPage;
