import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
export const payment = (
  <div>
    <div>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '0.01'
                }
              }
            ]
          });
        }}
      />
    </div>
  </div>
);

//  default payment;
