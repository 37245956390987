import React, { useState } from 'react';
import Layout from '../Components/layout/Layout';
import RecentPost from '../Components/RecentPost';
import Subscription from '../Components/Subscription';
import ProjectImage from '../Assets/image/2.png';
import Contact from '../Components/Contact';
import RightArrow from '../Assets/arrow-right.png';
import { Link } from 'react-router-dom';

const PostPage = () => {
  const [open, setOpen] = useState(false);
  const handleDropDown = () => setOpen(!open);
  return (
    <Layout>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl leading-tight font-lato lg:mb-6 lg:text-4xl text-footer">
                The COVID-19 pandemic requires a global response. With 5.1 million people still
                without access to clean water in Rwanda, our work takes on a continued sense of
                urgency.
              </h1>
            </header>
            <p className="lead font-rubik text-footer">
              We have been supporting radio stars in Rwanda to produce a series of radio dramas to
              share messages about handwashing and hygiene, which is more important than ever before
            </p>
            <figure className="my-6">
              <img src={ProjectImage} alt="someImage" />
              <figcaption>Digital art by Anonymous</figcaption>
            </figure>
            <h1 className="mb-4 text-3xl leading-tight font-lato lg:mb-6 lg:text-4xl text-footer">
              water, toilets and hygiene in Rwanda
            </h1>
            <p className="font-rubik text-footer">
              W Anyone in doubt about whether change is possible should look to Rwanda. Following a
              brutal civil war, the country has made astounding progress. Three in five people now
              have clean water and two thirds of the population have a decent toilet, making this
              mountainous, green country a shining example of what we can achieve alongside our
              partners in country. The Government continues to follow its plan to reach everyone
              with clean water, toilets and hygiene. But, it's the people with the lowest incomes,
              often in remote and hard-to-reach areas, who still lack basic services. Policy-makers
              need information on how best to make improvements. And service providers need money to
              put lasting systems in place.
            </p>

            {/* <section class="not-format mt-10">
              <form class="mb-2">
                <div class="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                  <label for="comment" class="sr-only">
                    Your comment
                  </label>
                  <textarea
                    id="comment"
                    rows="6"
                    class="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                    placeholder="Write a comment..."
                    required></textarea>
                </div>
                <button
                  type="submit"
                  class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
                  Post comment
                </button>
              </form>
              <article class="p-6 mb-6 text-base bg-white rounded-lg dark:bg-gray-900">
                <footer class="flex justify-between items-center mb-2">
                  <div class="flex items-center">
                    <p class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                      <img
                        class="mr-2 w-6 h-6 rounded-full"
                        src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"
                        alt="Michael Gough"
                      />
                      Michael Gough
                    </p>
                    <p class="text-sm text-gray-600 dark:text-gray-400">
                      <time pubdate datetime="2022-02-08" title="February 8th, 2022">
                        Feb. 8, 2022
                      </time>
                    </p>
                  </div>
                </footer>
                <p className="text-footer">
                  Very straight-to-point project. Really worth time reading. Thank you! But tools
                  are just the instruments for the UX designers. The knowledge of the design tools
                  are as important as the creation of the design strategy.
                </p>
                <div class="flex items-center mt-4 space-x-4">
                  <button
                    type="button"
                    class="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400">
                    <svg
                      aria-hidden="true"
                      class="mr-1 w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                    </svg>
                    Reply
                  </button>
                </div>
              </article>

              <article class="p-6 text-base bg-white border-t border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                <footer class="flex justify-between items-center mb-2">
                  <div class="flex items-center">
                    <p class="inline-flex items-center mr-3 text-sm text-black dark:text-white">
                      <img
                        class="mr-2 w-6 h-6 rounded-full"
                        src="https://flowbite.com/docs/images/people/profile-picture-4.jpg"
                        alt="Helene Engels"
                      />
                      Helene Engels
                    </p>
                    <p class="text-sm text-gray-600 dark:text-gray-400">
                      <time pubdate datetime="2022-06-23" title="June 23rd, 2022">
                        Jun. 23, 2022
                      </time>
                    </p>
                  </div>
                </footer>
                <p className="text-footer">
                  Thanks for sharing this. I do came from the Backend development and explored some
                  of the tools to design my Side Projects.
                </p>
                <div class="flex items-center mt-4 space-x-4">
                  <button
                    type="button"
                    class="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400">
                    <svg
                      aria-hidden="true"
                      class="mr-1 w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                    </svg>
                    Reply
                  </button>
                </div>
              </article>
            </section> */}
            {/* <footer className="mb-4 lg:mb-2 lg:mt-12 not-format">
              <h4 className="mb-4 text-xl leading-tight font-lato lg:mb-2 text-footer">
                Make a donation today
              </h4>
              <p className="lead font-rubik text-gray-500">
                Ready to make a difference? Donate to WaterAid and help change someone's life.
              </p>
              <a href="/donete-step1">
                <div className="flex cursor-pointer">
                  <h2 className="font-lato text-primary hover:underline">Donate Now</h2>
                  <img className="w-6" src={RightArrow} />
                </div>
              </a>
            </footer> */}
          </article>
        </div>
      </main>
      <RecentPost />
      <Subscription />
      <Contact />
    </Layout>
  );
};

export default PostPage;
