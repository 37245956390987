import axios from 'axios';

var token = null;

if (process.browser) {
  token = localStorage.getItem('token');
}
const http = axios.create({
  baseURL: process.env.BASE_URL,
  // headers: {
  //   Authorization: token,
  //   'Content-type': 'application/json',
  // },
});

class ServicesService {
  getAllServices() {
    return http.get(`/api/v1/services`);
  }
  addService(service){
    return http.post(`/api/v1/services`, service)
  }

  deleteService(service){
    return http.delete(`/api/v1/services/${service}`)
  }
  getService(service){
    return http.get(`/api/v1/services/${service}`)
  }
  updateService(id, service){
    return http.put(`/api/v1/services/${id}`, service)
  }
}

export default new ServicesService();