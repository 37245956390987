import React from 'react';
import SidebarItem from './SidebarItem';
import items from '../../utils/sidebar.json';
import '../dashboard.css';

const Sidebar = () => {
  return (
    <div className="sidebar bg-footer pt-20 hidden lg:block">
      {items.map((item, index) => (
        <SidebarItem key={index} item={item} />
      ))}
    </div>
  );
};
export default Sidebar;
