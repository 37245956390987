import React from 'react';

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div key={index} className={index === activeIndex ? 'slides bg-frame1 rounded-lg active' : 'inactive rounded-lg'}>
          <img className="slide-image rounded-lg object-cover" src={slide.urls} alt="contentImage" />
          <div className="content Container">
            <h2 className="slide-title text-xl font-lato text-black">{slide.title}</h2>
            {/* <h3 className="slide-text -text-center font-rubik text-black">{slide.description}</h3> */}
          </div>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
