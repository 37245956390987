import {combineReducers} from "redux";
import {
  projectsReducer
} from "./projects.reducers";
import {
  servicesReducer
} from "./services.reducers";

export const rootReducer = combineReducers({
  projects: projectsReducer,
  services: servicesReducer
})
