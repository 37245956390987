import React from 'react';
import Logo from '../Assets/image/logo.png';
import {
  HiChevronRight,
  HiLocationMarker,
  HiOutlineDeviceMobile,
  HiOutlineMail,
  HiOutlinePhone
} from 'react-icons/hi';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer aria-label="Site Footer" className="bg-footer">
      <div className="max-w-screen-xl px-4 py-16 mx-auto space-y-8 sm:px-6 lg:space-y-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <div className="text-teal-600">
              <Link to="/">
                <img className="h-12 object-cover" src={Logo} alt="logo" />
              </Link>
            </div>

            <p className="max-w-xs mt-4 text-footer-text font-rubik">
              We carry out all large commercial borehole drilling operations.
            </p>

            <ul className="flex gap-6 mt-8">
              <li>
                <a
                  href="/link"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-dark transition hover:opacity-75">
                  <span className="sr-only">Facebook</span>

                  <svg
                    className="w-6 h-6 bg-primary rounded-full"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="/link"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-dark transition hover:opacity-75">
                  <span className="sr-only">Twitter</span>

                  <svg
                    className="w-6 h-6 bg-primary rounded-full"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="/link"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-dark transition hover:opacity-75">
                  <span className="sr-only">linkedin</span>

                  <svg
                    className="w-6 h-6 bg-primary rounded-full"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3">
            <div>
              <p className="font-lato text-footer-text text-xl">Our Company</p>

              <nav aria-label="Footer Navigation - Services" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <Link to="/about"
                      className="text-footer-text text-xs transition font-rubik hover:opacity-75 pl-4">
                      Who We Are
                    </Link>
                  </li>

                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <Link to="/about"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      About Company
                    </Link>
                  </li>

                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <Link to="/service"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      Services We Provide
                    </Link>
                  </li>

                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <Link to="project"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      What We Have Done
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div>
              <p className="font-lato text-footer-text text-xl">Our Services</p>

              <nav aria-label="Footer Navigation - Company" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <a href="#link"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      Underground Water Detecting
                    </a>
                  </li>

                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <a href="#link"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      Drilling And Exploration
                    </a>
                  </li>

                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <a href="#link"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      Pump Installations
                    </a>
                  </li>
                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <a href="#link"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      Irrigation Schame
                    </a>
                  </li>

                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <a href="#link"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      Rain Water Harvesting And Treatment
                    </a>
                  </li>

                  <li className="flex">
                    <HiChevronRight className="h-6 w-6 text-primary" />
                    <a
                      href="#link"
                      className="text-footer-text text-xs font-rubik transition hover:opacity-75 pl-4">
                      Water Pipelines And Supply
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div>
              <p className="font-lato text-footer-text text-xl">Contact Details</p>

              <nav aria-label="Footer Navigation - Company" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li className="flex">
                    <HiLocationMarker className="h-6 w-6 text-primary" />
                    <a
                      href="#contact"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      NR3, Kigali
                    </a>
                  </li>

                  <li className="flex">
                    <HiOutlinePhone className="h-6 w-6 text-primary" />
                    <a
                      href="#contact"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      (250) 782 189 817
                    </a>
                  </li>

                  <li className="flex">
                    <HiOutlineDeviceMobile className="h-6 w-6 text-primary" />
                    <a
                      href="#contact"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      (250) 788 319 261
                    </a>
                  </li>
                  <li className="flex">
                    <HiOutlineMail className="h-6 w-6 text-primary" />
                    <a
                      href="#contact"
                      className="text-footer-text font-rubik text-xs transition hover:opacity-75 pl-4">
                      info@gaiasurveyrwanda.com
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <hr className="my-6 w-full border-blueGray-300" />

        <p className="text-xs text-footer-text font-rubik text-center">
          &copy; 2022. Gaia Survey Rwanda. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
