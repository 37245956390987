import React, { useEffect, useState } from 'react';
import Dashboard from '../Dashboard';
import Image from '../../Assets/image/Borehole-and-solar-pump.jpg'
import { FaTrash, FaEdit } from 'react-icons/fa';
import {
  addService,
  getAllServices,
  deleteService,
  getService,
  updateService
} from '../../redux/actions/services.action';
// import Notifications, {notify} from 'react-notify-toast';
import { useDispatch } from 'react-redux';
// import moment from 'moment';

const Services = () => {
  const [createServiceModel, setCreateServiceModel] = useState(false);
  const [deleteServiceModel, setDeleteServiceModel] = useState(false);
  const [updateServiceModel, setUpdateServiceModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  // const [start, setStart] = useState();
  // const [end, setEnd] = useState();
  // const [image, setImage] = useState();
  const [serviceId, setServiceId] = useState();
  const [serviceName, setServiceName] = useState();
  const [serviceUpdate, setServiceUpdate] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllServices()).then((response) => {
      setServices(response.data);
    });
  }, []);

  const removeModel = () => {
    let newState = !createServiceModel;
    setCreateServiceModel(newState);
  };

  const deleteServices = (e, service_id) => {
    e.preventDefault();
    setLoading(true);
    dispatch(deleteService(service_id))
      .then((response) => {
        setLoading(false);
        setDeleteServiceModel(false);
        dispatch(getAllServices()).then((response) => {
          setServices(response.data);
        });
        // notify.show("this is sample text", "success", 5000, "green");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const updateServices = (e, service_id) => {
    e.preventDefault();
    setLoading(true);
    dispatch(updateService(service_id, name, description))
      .then((response) => {
        console.log('updated', response);
        setLoading(false);
        setUpdateServiceModel(false);

        dispatch(getAllServices()).then((response) => {
          setServices(response.data);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log('err.message');
  };

  const removeDeleteModel = (id, service_name) => {
    setServiceId(id);
    setServiceName(service_name);
    let newState = !deleteServiceModel;
    setDeleteServiceModel(newState);
  };

  const updateModel = (updateData) => {
    setServiceUpdate(updateData);
    setServiceId(updateData.id);
    let newState = !updateServiceModel;
    setUpdateServiceModel(newState);
  };

  const createService = () => {
    setLoading(true);
    dispatch(addService(name, description)).then((response) => {
      setLoading(false);
      removeModel();
      dispatch(getAllServices()).then((response) => {
        setServices(response.data);
      });
    });
  };

  return (
    <Dashboard>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      {/* ===========================Start: CreateServiceModel============== */}
      <div
        className={`h-screen w-4/5 bg-gray-600 bg-opacity-30 backdrop-blur-sm mt-[90%] lg:mt-12 fixed flex items-center justify-center z-10 ${
          createServiceModel === true ? 'block' : 'hidden'
        }`}>
        <div className="bg-white w-1/2 shadow-2xl rounded-lg p-4 pb-8">
          <div className="card-title w-full flex flex-wrap justify-center items-center  ">
            <h3 className="font-bold text-sm text-center w-11/12 uppercase">Add Service</h3>
            <hr className=" bg-primary border-b my-3 w-full" />
          </div>
          <div className="card-body">
            <form className="px-8">
              <div className="input">
                <div className="grouped-input flex items-center w-full rounded-md">
                  <input
                    type="text"
                    name="name"
                    className="border border-gray-300 rounded outline-none px-2 font-sans text-xs py-2.5 w-full"
                    placeholder="Service Title"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="input my-3 h-16 mt-4">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <textarea
                    type="text"
                    name="name"
                    className="border border-gray-300 py-2 pb-10 rounded outline-none px-2 font-sans text-xs w-full"
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="input my-3 h-9 mt-4">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <input
                    type="text"
                    name="name"
                    className="border border-gray-300 rounded outline-none px-2 font-sans text-xs py-2.5 w-full"
                    placeholder="Staring Date"
                    onChange={(e) => setStart(e.target.value)}
                  />
                </div>
              </div> */}
              {/* <div className="input my-3 h-9 mt-2">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <input
                    type="text"
                    name="name"
                    className="border border-gray-300 rounded outline-none px-2 font-sans text-xs py-2.5 w-full"
                    placeholder="Ending Date"
                    onChange={(e) => setEnd(e.target.value)}
                  />
                </div>
              </div> */}
              {/* <div className="input my-3 h-9 mt-2">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <input
                    type="text"
                    className=" border border-gray-300 py-2.5 rounded outline-none px-2 font-sans text-xs w-full"
                    placeholder="inout image"
                    onChange={(e) => setImage(e.target.value)}
                  />
                </div>
              </div> */}
              <div className="w-full flex justify-between mt-8">
                <button
                  className="py-2 w-[40%] md:w-1/3 bg-transparent rounded border border-primary font-sans text-sm text-gray-900"
                  onClick={(e) => removeModel(e.preventDefault())}>
                  Cancel
                </button>
                <button
                  className="py-2 w-[40%] md:w-1/3 rounded text-white  bg-footer hover:bg-transparent border border-primary hover:text-black hover:bg-white focus:ring-4 focus:outline-none"
                  onClick={(event) => createService(event.preventDefault())}>
                  {loading ? 'Loading...' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* ===========================End: CreateServiceModel============== */}

      {/* =========================== Start::  deleteServiceModel =============================== */}
      <div
        className={`min-h-full w-4/5 z-30 bg-gray-600 bg-opacity-30 lg:mt-12 backdrop-blur-sm fixed flex items-center justify-center px-4 ${
          deleteServiceModel === true ? 'block' : 'hidden'
        }`}>
        <div className="bg-white w-full sm:w-3/4 md:w-1/2 xl:w-4/12 rounded-lg p-4 pb-8">
          <div className="card-title w-full flex  flex-wrap justify-center items-center  ">
            <h3 className="font-bold text-sm text-center w-11/12">Remove Service</h3>
            <hr className=" bg-primary border-b w-full" />
          </div>
          <div className="card-body">
            <form className=" px-8">
              <div>
                <h2 className="text-base m-4">
                  Do you really want to remove{' '}
                  <span className="italic text-black">Service {serviceName}</span> from the
                  DataBase?
                </h2>
              </div>
              <div className="w-full flex justify-between">
                <button
                  className="py-2 w-[40%] md:w-1/3 bg-transparent rounded border border-primary font-sans text-sm"
                  onClick={(e) => removeDeleteModel(e.preventDefault())}>
                  Cancel
                </button>
                <button
                  onClick={(e) => deleteServices(e, serviceId)}
                  className="text-white py-2 w-[40%] md:w-1/3 bg-red-light rounded">
                  {loading ? 'Loading...' : 'Delete'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* =========================== End::  deleteServiceModel =============================== */}

      {/* =========================== Start::  updateServiceModel =============================== */}
      <div
        className={`min-h-full w-4/5 z-30 bg-gray-500 bg-opacity-30 backdrop-blur-sm lg:mt-12 fixed flex items-center justify-center px-4 ${
          updateServiceModel === true ? 'block' : 'hidden'
        }`}>
        <div className="bg-white sm:w-3/4 md:w-1/2  xl:w-4/12 rounded-lg p-4 pb-8">
          <div className="card-title w-full flex  flex-wrap justify-center items-center  ">
            <h3 className="font-bold text-sm text-gray-700 text-center w-11/12">
              Update <span className="italic text-black">{serviceUpdate.name}</span> Info
            </h3>
            <hr className=" bg-primary border-b my-3 w-full" />
          </div>
          <div className="card-body">
            <form className="px-8">
              <div className="input">
                <div className="grouped-input flex items-center w-full rounded-md">
                  <input
                    type="text"
                    name="name"
                    defaultValue={serviceUpdate.name}
                    onChange={(e) => setName(e.target.value)}
                    className="border border-gray-300 rounded outline-none px-2 font-sans text-xs py-2.5 w-full"
                    placeholder="Service Title"
                  />
                </div>
              </div>
              <div className="input my-3 h-16 mt-4">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <textarea
                    type="text"
                    name="name"
                    defaultValue={serviceUpdate.description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="border border-gray-300 py-2 pb-10 rounded outline-none px-2 font-sans text-xs w-full"
                    placeholder="Description"
                  />
                </div>
              </div>
              {/* <div className="input my-3 h-9 mt-4">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <input
                    type="text"
                    name="name"
                    defaultValue={serviceUpdate.start}
                    onChange={(e) => setStart(e.target.value)}
                    className="border border-gray-300 rounded outline-none px-2 font-sans text-xs py-2.5 w-full"
                    placeholder="Starting Date"
                  />
                </div>
              </div> */}
              {/* <div className="input my-3 h-9 mt-2">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <input
                    type="text"
                    name="name"
                    defaultValue={serviceUpdate.end}
                    onChange={(e) => setEnd(e.target.value)}
                    className="border border-gray-300 rounded outline-none px-2 font-sans text-xs py-2.5 w-full"
                    placeholder="Ending Date"
                  />
                </div>
              </div> */}
              {/* <div className="input my-3 h-9 mt-2">
                <div className="grouped-input flex items-center h-full w-full rounded-md">
                  <input
                    type="text"
                    defaultValue={serviceUpdate.image}
                    onChange={(e) => setImage(e.target.value)}
                    className=" border border-gray-300 py-2.5 rounded outline-none px-2 font-sans text-xs w-full"
                    placeholder="Image URL here"
                  />
                </div>
              </div> */}
              <div className="w-full flex justify-between mt-8">
                <button
                  className="py-2 w-[40%] md:w-1/3 bg-transparent rounded border border-primary font-sans text-sm text-gray-900"
                  onClick={(e) => {
                    e.preventDefault();
                    setUpdateServiceModel(false);
                  }}>
                  Cancel
                </button>
                <button
                  onClick={(e) => updateServices(e, serviceId)}
                  className="py-2 w-[40%] md:w-1/3 rounded text-white  bg-footer hover:bg-transparent border border-primary hover:text-black hover:bg-white focus:ring-4 focus:outline-none">
                  {loading ? 'Loading...' : 'Update'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* =========================== End::  updateServiceModel =============================== */}

      <div className="flex items-left px-4 lg:px-7 pt-14 pb-8">
        <div className="space-x-8">
          <button
            className="font-serif bg-[#2563eb] hover:bg-transparent border border-[#2563eb] hover:text-black hover:bg-white font-medium rounded-lg text-base px-5 py-2.5 mt-8 text-center mr-3 md:mr-0 cursor-pointer"
            onClick={removeModel}>
            Services +
          </button>
        </div>
      </div>

      <div className="pb-10 min-h-screen px-2  lg:px-10">
        <div className="md:px-22">
          <div className="bg-[#191919] shadow-2xl px-5 py-8 rounded-md w-full lg:w-full ">
            <div>
              <div className="-mx-12 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div className="inline-block lg:min-w-full shadow-xl rounded-lg overflow-hidden">
                  <table className="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className="font-lato px-5 py-3 border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold uppercase tracking-wider">
                          Name
                        </th>
                        <th className="font-lato px-5  border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold uppercase tracking-wider">
                          Descriptions
                        </th>
                        {/* <th className="font-lato p-6 border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold uppercase tracking-wider">
                          Starting Date
                        </th> */}
                        {/* <th className="font-lato px-5  border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold uppercase tracking-wider">
                          Completion Date
                        </th> */}
                        <th className="font-lato px-5  border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold uppercase tracking-wider">
                          Image
                        </th>
                        <th className="font-lato px-5 py-3 border-b-2 border-gray-200 bg-gray-300 text-left text-xs font-semibold uppercase tracking-wider">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {services.map((item, index) => (
                        <tr key={index}>
                          <td className="px-5 py-3 border-b border-gray-200 text-sm">
                            <p className="text-gray-900 whitespace-no-wrap line-clamp-3 font-semibold font-lato">
                              {item.name}
                            </p>
                          </td>
                          <td className="px-5 py-3 border-b border-gray-200 text-xs">
                            <p className="text-gray-900 whitespace-no-wrap line-clamp-4 font-bold font-sans">
                              {item.description}
                            </p>
                          </td>
                          {/* <td className="px-5 py-3 border-b border-gray-200  text-xs">
                            <div className="flex items-center">
                              <div>
                                <p className="text-gray-900 whitespace-no-wrap font-bold font-sans">
                                  {moment(item.start).format('YYYY-MM-DD')}
                                </p>
                              </div>
                            </div>
                          </td> */}

                          {/* <td className="px-5 py-3 border-b border-gray-200  text-xs">
                            <div className="flex items-center">
                              <div>
                                <p className="px-4 text-gray-900 whitespace-no-wrap font-bold font-sans">
                                  {moment(item.end).format('YYYY-MM-DD')}
                                </p>
                              </div>
                            </div>
                          </td> */}
                          <td className="px-5 py-3 border-b border-gray-200 text-sm">
                            <div className="flex items-center">
                              <img
                                className="object-cover h-10 w-10 rounded-full"
                                src={Image}
                                alt="ServiceImage"
                              />
                            </div>
                          </td>
                          <td className="px-5 py-3 border-b border-gray-200 cursor-pointer text-lg">
                            <div className="flex">
                              <div
                                className="cursor-pointer mr-2 text-footer"
                                onClick={() => updateModel(item)}>
                                <FaEdit />
                              </div>
                              <div
                                className="cursor-pointer text-red-light"
                                onClick={() => removeDeleteModel(item.id, item.name)}>
                                <FaTrash />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Services;
