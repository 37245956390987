import React from 'react';
import Contact from '../Components/Contact';
import Home from '../Components/Home';
import Layout from '../Components/layout/Layout';
import { Icon } from '@iconify/react';
import Image1 from '../Assets/image/1.jpg';
import Image2 from '../Assets/image/our_team.png';
import Image3 from '../Assets/image/hqdefault.jpg';
import { ourEquipments } from '../Data';
import EquipmentItem from './EquipmentItem';
const AboutPage = () => {
  return (
    <Layout>
      <div className="pb-24 pt-8 bg-white md:px-32">
        <div className="bg-opacity-50 w-screen lg:w-full flex items-center justify-center">
          <p className="lg:text-3xl text-xl text-footer font-bold pt-8">Background</p>
        </div>
        <hr className="lg:w-32 w-10 lg:my-2 h-1 border-none text-center items-center mx-auto bg-primary" />
        <div className="mx-2 grid grid-cols-1 lg:px-40 mt-2">
          <div className="lg:pr-0">
            <p className="font-bold text-gray-700 text-xs my-2">
              Gaia Survey Rwanda Ltd is a drilling of water Wells Company managed by well qualified
              Engineers and Technicians possessing long-standing experience in varies facets of drilling,
              construction, water and project management.
            </p>
            <p className="font-bold text-gray-700 text-xs my-2">
              The company was pledge to meet the growing demand of engineering industries in both
              Government and private sectors with the advert of time and considering the modernized
              requirement of the 21 st century. The client require the state of art and stand out Engineering
              solution which will make them feel they are class apart from others and hence we diversified our
              business so that client can have a detailed solution from A to Z under one roof.
            </p>
            <p className="font-bold text-gray-700 text-xs my-2">
              Our team is dedicated to delivering solutions that are, drilling of water wells, water supply,
              construction of buildings and project management services. With several experience in
              engineering our team consistently offers the most advanced engineering solutions to fit every
              budget and schedule. As the part of Gaia Survey Rwanda Ltd we remain committed in
              innovation.
            </p>
          </div>
        </div>
        <div className="bg-opacity-50 w-screen lg:w-full flex items-center justify-center">
          <p className="lg:text-3xl text-xl text-footer font-bold pt-8">Equipments</p>
        </div>
        <div className="mx-2 grid grid-cols-1 lg:px-40">
          <div className="lg:pr-0 ">
            <hr className="lg:w-32 w-10 lg:my-2 h-1 s border-none text-center items-center mx-auto bg-primary" />
            <p className="font-bold text-gray-700 pt-2 my-2 text-xs">
            Second only to the people who run them, PRD SPEED STAR, GEMROK 45 SDD, 2Nos of Air compressor, 
            Drill Carriers, Drills, Down-hole equipment and Support pick up car; allowing us a multitude of
            Configurations and drilling methods.</p>
            <p className="font-bold text-gray-700 my-2 text-xs">Our equipment is maintained by a highly qualified and certified mechanical staff we are highly proficient in the 
            shallow to medium depth exploration sector, with a range of drilling machinery and in-hole equipment, 
            As our daily rate is considerably lower than the main production rig rate you can not only
            reduce the costs due to our understanding of regulations, you can also be sure that your surface casings and 
            aquifer protection requirements are met.
            We are confident that we have the equipment to meet your requirement</p>
          </div></div>
        {/* <div className="grid lg:grid-cols-2 overflow-x-hidden z-0 h-auto md:grid-cols-2 gap-8 mx-2 w-full"> */}
        {/* {ourEquipments.map((item) => (
        <EquipmentItem
          key={item.id}
          id={item.id}
          image={item.urls}
          image_1={item.urls_1}
          title={item.title}
          description={item.description}
        />
      ))} */}
        {/* </div> */}
        {/* <div className="bg-opacity-50 w-screen lg:w-full flex items-center justify-center">
          <p className="lg:text-3xl text-xl text-footer font-bold pt-8">Mission</p>
        </div>
        <hr className="lg:w-32 w-10 lg:my-2 h-1 border-none text-center items-center mx-auto bg-primary" />
        <div className="mx-2 grid grid-cols-1 lg:px-40 mt-8">
          <div className="lg:pr-0">
            <p className="font-bold text-gray-700 my-2">
              Provide technical excellent and innovative solution to customers.To procure projects at competitive pricing provide safe working conditions and deliver quality
              work with reasonable time frame.
            </p>
          </div>
        </div> */}
        {/* <div className="bg-opacity-50 w-screen lg:w-full flex items-center justify-center">
          <p className="lg:text-3xl text-xl text-footer font-bold pt-8">Objectives</p>
        </div>
        <hr className="lg:w-32 w-10 lg:my-2 h-1 border-none text-center items-center mx-auto bg-primary" />
        <div className="mx-2 grid grid-cols-1 lg:px-40 mt-8">
          <div className="lg:pr-0">
            <p className="font-bold text-gray-700 my-2">
              Our objectives is to provide our clients with an &lt; I am assured&gt; experience when we are chosen
              execute their projects. Our emphasis on clear communication and follow through procedures
              ensure that client’s objectives are on top priority in planning and execution of all our process.
            </p>
          </div>
        </div> */}
        <div className="bg-opacity-50 w-screen lg:w-full flex items-center justify-center">
          <p className="lg:text-3xl text-xl text-footer font-bold pt-8">Our Team</p>
        </div>
        <hr className="lg:w-32 w-10 lg:my-2 h-1 border-none text-center items-center mx-auto bg-primary" />
        <div className="lg:px-12 mt-8">
          <div className="lg:pr-0 flex flex-col md:flex-row lg:flex-row items-center justify-center cursor-pointer">

            <img
              className={'lg:w-full w-3/2 lg:h-auto object-cover rounded-2xl lg:mx-0 mx-2 h-21'}
              style={{ width: '550px' }}
              src={Image2}
              alt={'profile image'}
            />

            {/* <div className="lg:mx-8 lg:my-10 my-2 w-full lg:w-1/4 shadow-2xl hover:cursor-pointer">
              <div className="relative">
                <div className="p-8">
                  <img
                    className={'lg:w-full w-full lg:h-52 object-cover lg:mx-0 mx-2 h-64'}
                    src={Image2}
                    alt={'profile image'}
                  />
                </div>
              </div>
              <div className="flex flex-col px-5 bg-transparent items-center justify-center">
                <h1 className="text-sm lg:text-xl text-footer font-bold mt-4">
                  Damien HAGIRINSHUTI
                </h1>
                <h2 className="text-xs lg:text-md text-primary font-bold">CEO, Founder</h2>

                <p className="lg:py-2 font-lato text-gray-700">
                  simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                  the industry's standard dummy.
                </p>
              </div>
            </div> */}
            {/* <div className="lg:mx-8 lg:my-10 my-2 w-full lg:w-1/4 shadow-2xl hover:cursor-pointer">
              <div className="relative">
                <div className="p-8">
                  <img
                    className={'lg:w-full w-full lg:h-52 object-cover lg:mx-0 mx-2 h-64'}
                    src={Image2}
                    alt={'profile image'}
                  />
                </div>
              </div>
              <div className="flex flex-col px-5 bg-transparent items-center justify-center">
                <h1 className="text-sm lg:text-xl text-footer font-bold mt-4">
                  Damien HAGIRINSHUTI
                </h1>
                <h2 className="text-xs lg:text-md text-primary font-bold">CEO, Founder</h2>

                <p className="lg:py-2 font-lato text-gray-700">
                  simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                  the industry's standard dummy.
                </p>
              </div>
            </div> */}
            {/* <div className="lg:mx-8 lg:my-10 my-2 w-full lg:w-1/4 shadow-2xl hover:cursor-pointer">
              <div className="relative">
                <div className="p-8">
                  <img
                    className={'lg:w-full w-full lg:h-52 lg:mx-0 mx-2 h-64 object-cover'}
                    src={Image3}
                    alt={'profile image'}
                  />
                </div>
              </div>
              <div className="flex flex-col px-5 bg-transparent items-center justify-center">
                <h1 className="text-sm lg:text-xl text-footer font-bold mt-4">
                  Damien HAGIRINSHUTI
                </h1>
                <h2 className="text-xs lg:text-md text-primary font-bold">CEO, Founder</h2>

                <p className="lg:py-2 font-lato text-gray-700">
                  typesetting simply dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Contact />
    </Layout>
  );
};

export default AboutPage;
