import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Components/layout/Layout';
import LeftArrow from '../Assets/leftArrow.png';

const Donate2 = () => {
  return (
    <Layout>
      <div className="flex h-full justify-center items-center">
          <form className="w-full max-w-lg mx-auto">
            <Link to="/donete-step1">
              <img className="h-4 w-4 -mb-6" src={LeftArrow} />
            </Link>
            <div className="mb-1 text-footer text-center font-medium mt-8">Step 2 of 5</div>
            <div className="w-4/5 mx-auto bg-gray-200 rounded-full h-1.5">
              <div className="bg-primary h-1.5 rounded-full w-1/4"></div>
            </div>
            <div className="w-full px-3">
              <p className="text-footer text-center text-sm font-rubik">
                You are making a one-off donation of $500.
              </p>
              <h1 className="block font-lato text-center uppercase  tracking-wide text-footer text-sm font-bold mb-4">
                Your details
              </h1>
            </div>
            <div className="flex flex-wrap mx-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-footer font-rubik text-xs mb-2"
                  for="grid-password">
                  Title
                </label>
                <input
                  className="block uppercase pl-4 w-full py-2 tracking-wide leading-tight border border-footer rounded bg-white text-gray-700 text-xs my-2 focus:outline-none focus:bg-white  focus:border-gray-500"
                  id="grid-password"
                  type="text"
                  placeholder="Mr"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase font-rubik tracking-wide text-footer text-xs"
                  for="grid-first-name">
                  First Name
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700  border border-footer rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white  focus:border-gray-500"
                  id="grid-first-name"
                  type="text"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-footer font-rubik text-xs"
                  for="grid-last-name">
                  Last Name
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-footer rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide font-rubik text-footer text-xs"
                  for="grid-password">
                  Search for your address…
                </label>
                <input
                  className="block uppercase pl-4 w-full py-2 tracking-wide leading-tight border border-footer rounded bg-white text-gray-700 text-xs my-2 focus:outline-none focus:bg-white  focus:border-gray-500"
                  id="grid-password"
                  type="text"
                  placeholder="Start typing your address or postcode"
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide font-rubik text-footer text-xs"
                  for="grid-password">
                  Contact Email
                </label>
                <input
                  className="block uppercase pl-4 w-full py-2 tracking-wide  border border-footer leading-tight rounded bg-white text-gray-700 text-xs font-bold my-2 focus:outline-none focus:bg-white  focus:border-gray-500"
                  id="grid-password"
                  type="text"
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide font-rubik text-footer text-xs"
                  for="grid-password">
                  Contact phone
                </label>
                <input
                  className="block uppercase pl-4 w-full py-2 tracking-wide leading-tight  border border-footer rounded bg-white text-gray-700 text-xs font-bold my-2 focus:outline-none focus:bg-white  focus:border-gray-500"
                  id="grid-password"
                  type="text"
                />
              </div>
            </div>
            <div className="flex flex-wrap mx-3">
              <div className="w-full px-3">
                <button
                  className="block uppercase w-full py-3 font-sans tracking-wide leading-tight border-white rounded bg-primary text-center text-white text-xs font-bold my-2"
                  for="grid-password">
                  <Link to="/donete-step3">Next step</Link>
                </button>
              </div>
            </div>
          </form>
        </div>
    </Layout>
  );
};

export default Donate2;
