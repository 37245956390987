import React from 'react';
import ProjectImage1 from '../Assets/image/best-water-purifier-for-borewell-water.jpg'
import ProjectImage2 from '../Assets/image/AAEAAQAAAAAAAAgYAAAAJGZiNjcwZTY1LTMwMzgtNGZkNy04NmNhLTc3ZjIxNDMyNzBkMA.jpg'
import ProjectImage3 from '../Assets/image/drip1-blog480.jpg'
import ProjectImage4 from '../Assets/image/stock-photo-chiller-water-pump-chiller-water-pump-in-the-basement-286578356.jpg'

const RecentPost = () => {
  return (
    <aside aria-label="Related articles" className="py-8 lg:py-24 bg-footer">
      <div className="px-6 lg:px-14 mx-auto max-w-screen-xl ">
        <h2 className="mb-8 text-3xl font-lato">Related Project</h2>
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
          <article className="max-w-xs">
            <a href="#link">
              <img
                src={ProjectImage1}
                className="mb-5 h-44 w-60 rounded-lg object-cover"
                alt="Image1"
              />
            </a>
            <h2 className="mb-2 text-xl font-lato leading-tight">
              <a href="#link">supply and pipeline construction</a>
            </h2>
            <p className="mb-4 font-rubikt">
            Gaia Survey Rwanda Ltd team is experienced in installation of water pipeline and its supply. We will help you to carry your water from the storage to your house at any distance. We will never regret choosing our team..
            </p>
            <a
              href="#link"
              className="inline-flex items-center font-sans underline underline-offset-4 hover:no-underline">
              Read in 2 minutes
            </a>
          </article>
          <article className="max-w-xs">
            <a href="#link">
              <img
                src={ProjectImage2}
                className="mb-5 h-44 w-60 rounded-lg object-coverr"
                alt="Image2"
              />
            </a>
            <h2 className="mb-2 text-xl font-lato leading-tight">
              <a href="#link">Design and construction of Irrigation scheme</a>
            </h2>
            <p className="mb-4 font-rubik">
            Gaia Survey Rwanda Ltd will help you with simple effective technology to increase resilience to droughts. It keeps the rainfall closer to where it falls and store it treated for any use afterwards reducing runoff and risk of flooding
            </p>
            <a
              href="#link"
              className="inline-flex items-center font-sans underline underline-offset-4 hover:no-underline">
              Read in 12 minutes
            </a>
          </article>
        </div>
      </div>
    </aside>
  );
};

export default RecentPost;
