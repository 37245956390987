import React from 'react';
const Contact = () => {
  return (
    <>
      <div
        className="bg-contact bg-cover bg-center bg-no-repeat flex lg:justify-center lg:py-10 py-4"
        id="contact">
        <div className="bg-header opacity-70 lg:h-5/6 lg:w-3/4 w-full">
          <div className="flex flex-col lg:px-20">
            <div className="flex flex-col justify-center items-center lg:py-10 py-2">
              <h1 className="lg:text-3xl text-lg text-footer font-lato pb-4">Get In Touch</h1>
              <p className="lg:mb-4 lg:text-sm text-sm mx-2 text-footer font-rubik">
                If you have any questions, or you’d like a quote for a project, fill out the form
                below.
              </p>
            </div>
            <form className="flex flex-col">
              <div className="flex lg:flex-row flex-col lg:justify-between justify-center items-center lg:mx-4">
                <input
                  className="bg-white text-footer focus:outline-none focus-visible:ring  lg:pl-4 pl-2 my-2 h-12  lg:w-5/12 w-11/12"
                  type="text"
                  placeholder="Name"
                />
                <input
                  className="bg-white text-footer focus:outline-none focus-visible:ring  lg:pl-4 pl-2 my-2 h-12  lg:w-5/12 w-11/12"
                  type="text"
                  placeholder="Email address"
                />
              </div>
              <div className="lg:py-8 px-5">
                <textarea
                  className="lg:w-full w-full text-footer lg:h-44 h-32 bg-white focus:outline-none focus-visible:ring lg:pl-4 pl-2 lg:pt-4 pt-2"
                  placeholder="Message..."
                />
              </div>
              <div className="flex justify-end">
                <button className="bg-footer rounded-lg hover:text-primary text-white font-bold lg:mb-4 mr-5 mb-2 lg:h-12 h-10 lg:w-40 w-32">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
