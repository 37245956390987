import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const Layout = (props) => {
  return (
    <div>
      <div className="fixed w-full z-20"><Header/></div>
      
      <main className="w-full py-16 z-10">{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
