import React, { useEffect } from 'react';
import ServicesList from './containers/ServicesList';
import { ourServices } from '../Data';
import Image_2 from '../Assets/image/solar-water-pump-systems.gif';
import { Link } from 'react-router-dom';

const Services = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  return (
    <>
      <div className="bg-no-repeat bg-cover">
        <div className="bg-opacity-50 w-screen lg:w-full flex items-center justify-center text-footer">
          <p className="lg:text-3xl font-lato pt-8 text-xl ">Our Services</p>
        </div>
        <hr className="lg:w-32 w-10 lg:my-2 h-1 border-none text-center items-center mx-auto bg-primary" />
      </div>
       <div className="w-full">

        <ServicesList serviceItems={ourServices} />

        {/* <div className="col-span-1 w-3/4 flex flex-row space-x-3 m-auto">
          <img src={Image_2} alt="our__service" />
        </div> */}
        {/* <button className={'text-white hover:text-footer font-bold lg:-mt-20 p-2 bg-primary lg:w-32 rounded-md'}>
          <Link to={'/service'}>View more</Link>
        </button> */}
      </div>
    </>
  );
};
export default Services;
