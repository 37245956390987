import React, { useEffect } from 'react';
import { useState } from 'react';
import RightSideBarList from './containers/RightSideBarList';
import { RightSideBarData } from '../Data';
import Image from '../Assets/image/2.png';
import { Popup } from './Popup';

import { getAllProjects } from '../redux/actions/projects.actions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const RightSideBar = () => {
  const [open, setOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProjects()).then((response) => {
      setProjects(response.data);
    });
  }, []);
  const hundleDonation = () => {
    setOpen(!open);
  };

  return (
    <aside className="mx-2">
      <div className="overflow-y-auto py-1 px-3 right-0 scrollbar-hide ">
        <div class="max-w-sm border border-gray-200 rounded-lg shadow-md">
          <a href="#link">
            <img className="rounded-t-lg object-cover" src={Image} alt="" />
          </a>
          <div className="p-5">
            <a href="#link">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-footer">Rwanda</h5>
            </a>
            <p className="mb-3 font-normal text-gray-700 text-xs">
              With 5.1 million people still lacking clean water in Rwanda, our work takes on a
              continued sense of urgency due to the global COVID-19 pandemic.
            </p>
            <Link to="./project">
              <button
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg focus:ring-3 focus:outline-none focus:ring-primary hover:text-black"
                // onClick={() => setOpen(hundleDonation)}
              >
                {/* {open ? <p>Thank you & close </p> : <p>Donate</p>} */}
                {/* <Link to="/project">Read more && Donate</Link> */}
                Current Project
              </button>
            </Link>

            {/* {open ? (
              <div>
                <Popup
                  text="Thank you for taking time to help us"
                  closePopup={() => setOpen(false)}
                />
              </div>
            ) : null} */}
          </div>
        </div>

        {/* <h1 className="pt-8 text-footer">Recent project</h1>
        <hr className="w-24 pb-8" />
        <div className="py-2">
          <RightSideBarList rightSideItems={RightSideBarData} />
        </div>
        <div className="card-actions justify-center my-4">
          <button className="inline-flex items-center px-2 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg focus:ring-3 focus:outline-none focus:ring-primary hover:text-black">
            See all Projects
          </button>
        </div> */}
      </div>
    </aside>
  );
};

export default RightSideBar;
