import React from 'react';
import ServicesItem from './ServicesItem';

const ServicesList = (props) => {
  return (
    <div className="">
    <div className="grid lg:grid-cols-3 overflow-x-hidden z-0  md:grid-cols-2 gap-2 mx-2 w-full">
      {props.serviceItems.map((item) => (
        <ServicesItem
          key={item.id}
          id={item.id}
          image={item.urls}
          title={item.title}
          description={item.description}
        />
      ))}
    </div>
    </div>
  );
};
export default ServicesList;
