import {
  PROJECTS_ALL_SUCCESS,
  PROJECTS_ALL_LOADING,
  PROJECTS_ALL_ERROR
} from "../constants/projects.constants";

const initialState = {
  error: false,
  projects: []
}

export const projectsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECTS_ALL_LOADING:
      return { ...state, loading: true, error: false }
    case PROJECTS_ALL_ERROR:
      return { ...state, loading: false, error: payload };
    case PROJECTS_ALL_SUCCESS:
      return { ...state, loading: false, error: false, projects: payload };
    default:
      return state;
  }
};
