import React from 'react';
import Dashboard from '../Dashboard';
const Account = () => {
  return (
    <Dashboard>
      <div className="mt-20">Account</div>
    </Dashboard>
  );
};

export default Account;
