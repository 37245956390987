import React from 'react';
import Home from '../Components/Home';
import Services from '../Components/Services';
import Contact from '../Components/Contact';
import Expectation from '../Components/Expectation';
import Layout from '../Components/layout/Layout';

const HomePage = () => {
  return (
    <div>
      <Layout>
        <Home />
        <Services />
        {/* <Expectation /> */}
        <Contact />
      </Layout>
    </div>
  );
};

export default HomePage;
