import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//state
const state = createState({
  authUser: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true,
  updatedUser: null
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'AUTH_USER_INFO':
        return s.merge({
          authUser: action.data,
          loading: false,
          updateNeed: true,
          updatedUser: null
        });
      case 'USER_AUTH_UPDATE':
        return s.merge({
          updateNeed: true,
          updatedUser: action.data,
          loading: false
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessAuthState = () => state;

export const userAuthstate = () => useState(state);

//service
export const AuthService = {
  FetchAuthUsers: () => {
    console.log("I am here")
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/v1/users/`)
      .then((res) => {
        console.log("res:-", res.data.data.users)
        dispatch(AuthUserAction.fecthAuthUser(res.data.data.users));
      })
      .catch((err) => {});
  },
  UpdateAuthUser: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/users/updateUser/${id}`, data)
      .then((res) => {
        dispatch(AuthUserAction.updateAuthUser(res.data));
      })
      .catch((err) => {});
  }
};

//Action
const AuthUserAction = {
  fecthAuthUser: (data) => {
    return {
      type: 'AUTH_USER_INFO',
      data: data
    };
  },
  updateAuthUser: (data) => {
    return {
      type: 'USER_AUTH_UPDATE',
      data: data
    };
  }
};
