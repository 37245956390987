import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
// import Navbar from './Navbar';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { userAuthstate, AuthService } from '../Service/authenticationService';
import Layout from './layout/Layout';
function Login() {
  const authState = userAuthstate();
  const [currentTab, setCurrentTab] = useState(0);
  // const history = useHistory();
  useEffect(() => {
    AuthService.FetchAuthUsers();
  }, []);
  const users = authState.authUser.value;
  console.log('users:-', users);

  return (
    <Layout>
      {/* <Header /> */}
      <div className="bg-gray-100 min-h-screen h-max w-screen lg:w-full">
        {/* <Navbar /> */}
        <div className="grid md:grid-cols-2 xs:grid-row-2 gap-4 xs:grid-row-2 mx-auto lg:pt-10">
          <div className="place-self-center md:inline-block  xs:hidden">
            <h1 className="text-black font-bold lg:text-7xl mb-1">GAIA</h1>
            <h1 className="text-black font-bold lg:text-7xl mb-1">WATER</h1>
            <h1 className="text-black font-bold lg:text-7xl mb-1">PROJECTS</h1>
          </div>
          <div className="bg-white p-4 rounded-2xl md:w-4/6 xs:w-full place-self-center xs:mt-10">
            <ul id="tabs" className="flex mb-14">
              <li
                className={
                  currentTab === 0
                    ? `w-full px-7 font-semibold py-2 border-solid border-b-4 border-black text-center text-black`
                    : `w-full px-7 font-semibold py-2 border-solid border-b-4 border-transparent text-center`
                }
                onClick={() => {
                  setCurrentTab(0);
                }}>
                <p id="default-tab" className="cursor-pointer">
                  Log In
                </p>
              </li>
              <li
                className={
                  currentTab === 1
                    ? `w-full px-7 font-semibold py-2 border-solid border-b-4 border-black text-center text-Red`
                    : `w-full px-7 font-semibold py-2 border-solid border-b-4 border-transparent text-center`
                }
                onClick={() => {
                  setCurrentTab(1);
                }}>
                <p className="cursor-pointer">Sign Up</p>
              </li>
            </ul>
            <div>
              {currentTab === 0 ? (
                <>
                  <SignIn />
                  <p
                    className="text-darkGray font-regular italics text-center cursor-pointer"
                    onClick={() => setCurrentTab(2)}>
                    Forgot Password
                  </p>
                  <p
                    className="text-black font-bold text-center cursor-pointer"
                    onClick={() => setCurrentTab(1)}>
                    Don't have an account? Create one.
                  </p>
                </>
              ) : null}
              {currentTab === 1 ? (
                <>
                  <SignUp />
                  <p
                    className="text-black font-bold text-center cursor-pointer"
                    onClick={() => setCurrentTab(0)}>
                    Have an account already? Log in.
                  </p>
                </>
              ) : null}
              {currentTab === 2 ? (
                <>
                  <p
                    className="text-black font-bold text-center cursor-pointer"
                    onClick={() => setCurrentTab(1)}>
                    Don't have an account? Create one.
                  </p>
                  <p
                    className="text-black font-bold text-center cursor-pointer"
                    onClick={() => setCurrentTab(0)}>
                    Have an account already? Log in.
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </Layout>
  );
}

export default Login;
