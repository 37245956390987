import React from 'react';
import Slider from './Slider/Slider';
import RightSideBar from './RightSideBar';

const Home = () => {
  return (
    <div className="flex-grow block lg:flex h-full">
      <div className=" md:ml-2 lg:w-3/4 w-full mt-1">
        <Slider />
      </div>
      <div >
        <RightSideBar />
      </div>
    </div>
  );
};

export default Home;
