import mixpanel from 'mixpanel-browser';

const MIXPANEL_APP_ID = 'ded901f7301815341e3dec27831638e9';
mixpanel.init(MIXPANEL_APP_ID);

let actions = {
  signup: (user) => {
    mixpanel.alias(user.uid);
    mixpanel.track('Sign Up');
    mixpanel.people.set({
      $email: user.email,
      $name: user.username,
      'Registration Date': new Date().toISOString()
    });
  },
  login: (id) => {
    mixpanel.identify(id);
  },
  completeRegistration: (user) => {
    mixpanel.track('Complete Registration', {
      'Profile Name': user.name,
      $avatar: user.avatar,
      Bio: user.bio
    });
  },
  viewLivestream: (view) => {
    mixpanel.track('View Livestream', {
      'Streamer ID': view.streamer_id,
      'Stream Type': view.stream_type
    });

    mixpanel.people.set({
      'Last Livestream View': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Livestream View': new Date().toISOString()
    });

    mixpanel.people.increment('Total Livestream Views');
  },
  exitLivestream: (exit) => {
    mixpanel.track('Exit Livestream', {
      'Streamer ID': exit.streamer_id,
      'Stream Type': exit.stream_type
    });
  },
  viewCommunity: (community) => {
    mixpanel.track('View Community', {
      'Community ID': community.community_id,
      'Community Name': community.community_name
    });

    mixpanel.people.increment('Total Community Views');
  },
  initiateScheduleStream: (community) => {
    mixpanel.track('Initiate Schedule Event Stream', {
      'Community ID': community.community_id
    });
  },
  initiateStream: (community) => {
    mixpanel.track('Initiate Start Stream', {
      'Community ID': community.community_id
    });
  },
  scheduleStream: (event) => {
    mixpanel.track('Schedule Stream Event', {
      'Stream Name': event.stream_name,
      'Stream Type': event.stream_type,
      'Stream Event Date Time': event.stream_time,
      'Recipe?': event.stream_recipe,
      'Community ID': event.community_id
    });

    mixpanel.people.set({
      'Last Stream Event': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Stream Event': new Date().toISOString()
    });

    mixpanel.people.increment('Total Stream Events');
  },
  startStream: (stream) => {
    mixpanel.track('Start Stream', {
      'Stream Name': stream.stream_name,
      'Stream Type': stream.stream_type,
      'Recipe?': stream.stream_recipe,
      'Community ID': stream.community_id
    });

    mixpanel.people.set({
      'Last Stream': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Stream': new Date().toISOString()
    });

    mixpanel.people.increment('Total Streams');
  },
  socialShare: (share) => {
    mixpanel.track('Social Media Share', {
      'Social Media Type': share.share_type,
      'Scheduled Event?': share.scheduled,
      'Stream Type': share.stream_type
    });

    mixpanel.people.increment('Total Shares');
  },

  selectFeaturedProducts: (feature) => {
    mixpanel.track('Featured Products Selected', {
      Products: feature.products,
      'Stream Type': feature.stream_type
    });

    mixpanel.people.set({
      'Last Product Feature': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Product Feature': new Date().toISOString()
    });

    mixpanel.people.increment('Total Product Features');
  },

  initiateLivePurchase: (purchase) => {
    mixpanel.track('Initiate Livestream Purchase', {
      'Streamer ID': purchase.streamer_id,
      'Stream Type': purchase.stream_type,
      'Brand ID': purchase.brand_id,
      'Brand Name': purchase.brand_name,
      'Product ID': purchase.product_id,
      'Product Name': purchase.product_name,
      'Product Price': purchase.product_price
    });
  },

  saveProduct: (save) => {
    mixpanel.track('Livestream Product Bookmarked', {
      'Streamer ID': save.streamer_id,
      'Stream Type': save.stream_type,
      'Brand ID': save.brand_id,
      'Brand Name': save.brand_name,
      'Product ID': save.product_id,
      'Product Name': save.product_name,
      'Product Price': save.product_price,
      'Product Save Type': save.type
    });

    mixpanel.people.set({
      'Last Product Save': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Product Save': new Date().toISOString()
    });

    mixpanel.people.increment('Total Product Saves');
  },

  livePurchase: (purchase) => {
    mixpanel.track('Livestream Product Purchase', {
      'Streamer ID': purchase.streamer_id,
      'Stream Type': purchase.stream_type,
      'Brand ID': purchase.brand_id,
      'Brand Name': purchase.brand_name,
      'Product ID': purchase.product_id,
      'Product Name': purchase.product_name,
      'Product Price': purchase.product_price,
      'Product Quantity': purchase.product_quantity
    });

    mixpanel.people.set({
      'Last Product Purchase': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Product Purchase': new Date().toISOString()
    });

    mixpanel.people.increment('Total Product Purchases');
  }
};

export let Mixpanel = actions;
