import dotenv from 'dotenv';
import React from 'react';
import { Provider } from 'react-redux'
import store from './redux/store'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import HttpsRedirect from 'react-https-redirect';
import '@hookstate/devtools';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
require('dotenv').config();
const client = process.env.REACT_APP_STRIPE_PUBLIC_PAYPAL_CLIENT_KEY;

Sentry.init({
  dsn: 'https://3c0a0cc5735a460e949dd76cb401c621@o1262298.ingest.sentry.io/6440997',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});
dotenv.config();
ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <PayPalScriptProvider options={{ 'client-id': client }}>
        <Provider store={store} >
          <App />
        </Provider>
      </PayPalScriptProvider>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);
