import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Components/layout/Layout';
import LeftArrow from '../Assets/leftArrow.png';

const Donate3 = () => {
  return (
    <Layout>
      <div className="flex h-full justify-center items-center">
        <form className="w-full max-w-lg  mx-auto">
          <Link to="/donete-step2">
            <img className="h-4 w-4 -mb-6" src={LeftArrow} />
          </Link>
          <div className="mb-1 text-footer text-center font-medium mt-8 font-sans">Step 3 of 5</div>
          <div className="w-4/5 mx-auto bg-gray-200 rounded-full h-1.5 mb-6">
            <div className="bg-primary h-1.5 rounded-full dark:bg-blue-500 w-1/2"></div>
          </div>

          <div className="w-full px-3 mb-6">
            <p className="text-footer font-rubik text-center text-sm">
              You are making a one-off donation of $500.
            </p>
            <h1 className="block text-center uppercase tracking-wide text-footer font-lato text-sm mb-4">
              Stay updated
            </h1>
          </div>

          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3">
              <p className="text-footer font-rubik text-left text-sx">
                We’d love to keep you updated on how your support is making change happen. We will
                send you information about our work, campaigns, opportunities to get involved and
                fundraising. Simply let us know how you’d like to hear from us.
              </p>
              <div className="flex items-center mt-4 mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                />
                <label for="default-checkbox" className="ml-2 text-xs font-rubik text-gray-900">
                  Yes please, I'd like to hear from you by email
                </label>
              </div>
              <div className="flex items-center mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                />
                <label for="default-checkbox" className="ml-2 font-rubik text-xs text-gray-900">
                  Yes please, I'd like to hear from you by phone
                </label>
              </div>
              <div className="flex items-center mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                />
                <label for="default-checkbox" className="ml-2 text-xs font-rubik text-gray-900">
                  Yes please, I'd like to hear from you by SMS
                </label>
              </div>
              <div className="flex items-center mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                />
                <label for="default-checkbox" className="ml-2 text-xs font-rubik text-gray-900">
                  No thank you, please do not contact me by post
                </label>
              </div>
              <button
                className="block uppercase w-full py-3 tracking-wide border leading-tight border-white rounded bg-primary text-center text-white text-xs font-bold font-sans mt-4 my-2"
                for="grid-password">
                <Link to="/donete-step4">Next step</Link>
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Donate3;
