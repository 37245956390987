import Image_1 from '../../Assets/image/borehole.png';
import Image_2 from '../../Assets/image/drilling.png';
import Image_3 from '../../Assets/image/best-water-purifier-for-borewell-water.jpg';

export const sliderImage = [
  {
    title: 'POWER, STORAGE, PUMP AND TREATMENT',
    description:
      'Gaia Survey Rwanda Ltd technicians have expertise ranging from the installation of submersible pumps, tanks and filters to the setup of complex constant pressure systems. ',
    urls: Image_1
  },
  {
    title: 'DRILLING OF WATER WELLS',
    description:
      'With over 5 years of experience in drilling, completing, and bringing water wells as a solution of water shortage. As a result, we are confident we can meet your needs no matter what kind of water well you are drilling',
    urls: Image_2
  },
  {
    title: 'SUPPLY AND PIPELINE CONSTRUCTION',
    description:
      'Gaia Survey Rwanda Ltd team is experienced in installation of water pipeline and its supply. We will help you to carry your water from the storage to your house at any distance. We will never regret choosing our team',
    urls: Image_3
  }
];
